import { Container, Text, Button, Group, Stack, Loader } from '@mantine/core';
import notFound from '@/static/images/illustrations/undraw_page_not_found.svg';
import Link from 'next/link';
import classes from './404.module.css';
import { gql } from '@/__generated__';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { executeQuery } from '@/urql';
import get from 'just-safe-get';
import authState from '@/store/auth';

const testTeamIdQuery = gql(/* GraphQL */ `
  query TestTeamId($id: uuid!) {
    testsByPk(id: $id) {
      id
      teamId
    }
  }
`);

const blockTeamIdQuery = gql(/* GraphQL */ `
  query BlockTeamId($id: uuid!) {
    blocksByPk(id: $id) {
      id
      teamId
    }
  }
`);

const functionalityTeamIdQuery = gql(/* GraphQL */ `
  query FunctionalityTeamId($id: uuid!) {
    functionalitiesByPk(id: $id) {
      id
      teamId
    }
  }
`);

const suiteTeamIdQuery = gql(/* GraphQL */ `
  query SuiteTeamId($id: uuid!) {
    suitesByPk(id: $id) {
      id
      teamId
    }
  }
`);

const suiteExecutionTeamIdQuery = gql(/* GraphQL */ `
  query SuiteExecutionTeamId($id: uuid!) {
    suiteExecutionsByPk(id: $id) {
      id
      suiteId
      suite {
        id
        teamId
      }
    }
  }
`);

const autoTeamSwitcherConfig = {
  '/flows/tests/[testId]': {
    query: testTeamIdQuery,
    idKey: 'testId',
    teamIdKey: 'testsByPk.teamId',
  },
  '/flows/blocks/[blockId]': {
    query: blockTeamIdQuery,
    idKey: 'blockId',
    teamIdKey: 'blocksByPk.teamId',
  },
  '/functionality/[functionalityId]': {
    query: functionalityTeamIdQuery,
    idKey: 'functionalityId',
    teamIdKey: 'functionalitiesByPk.teamId',
  },
  '/suites/[suiteId]': {
    query: suiteTeamIdQuery,
    idKey: 'suiteId',
    teamIdKey: 'suitesByPk.teamId',
  },
  '/security/tests/[executionId]': {
    query: suiteExecutionTeamIdQuery,
    idKey: 'executionId',
    teamIdKey: 'suiteExecutionsByPk.suite.teamId',
  },
  '/apis/tests/[executionId]': {
    query: suiteExecutionTeamIdQuery,
    idKey: 'executionId',
    teamIdKey: 'suiteExecutionsByPk.suite.teamId',
  },
};

function getMatchingConfig(
  route: string,
):
  | (typeof autoTeamSwitcherConfig)[keyof typeof autoTeamSwitcherConfig]
  | undefined {
  for (const key in autoTeamSwitcherConfig) {
    if (route.startsWith(key)) {
      return (autoTeamSwitcherConfig as any)[key];
    }
  }
  return undefined;
}

export function NotFoundPage() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [teamId, setTeamId] = useState<string | null>(null);

  useEffect(() => {
    const config = getMatchingConfig(router.route);
    if (config) {
      const { query, idKey, teamIdKey } = config;
      const id = router.query[idKey];
      if (id) {
        const variables = { id };
        setLoading(true);
        setTeamId(null);
        executeQuery(query as any, variables, { user: true })
          .then((data) => {
            const teamId = get(data, teamIdKey);
            if (teamId && authState.selectedTeam?.id !== teamId) {
              setTeamId(teamId);
              return authState.switchTeam(
                teamId,
                undefined,
                window.location.href,
              );
            }
          })
          .catch(() => setTeamId(null))
          .finally(() => setLoading(false));
      }
    }
  }, [router.route, router.query]);

  return (
    <Container fluid className={classes.root}>
      <Stack>
        <img className={classes.image} src={notFound} alt="not found" />
        <Text c="dimmed" size="lg" ta="center" className={classes.description}>
          Page you are trying to open does not exist. You may have mistyped the
          address, or the page has been moved to another URL. If you think this
          is an error contact support.
        </Text>
        <Group justify="center">
          {(loading || teamId) && (
            <Button
              color="green"
              variant="outline"
              size="md"
              disabled
              leftSection={<Loader color="gray" size="xs" />}
            >
              {teamId
                ? 'Switching to the correct team'
                : 'Checking if this page belongs to a different team'}
            </Button>
          )}
          {!loading && !teamId && (
            <Link href="/" passHref={true} legacyBehavior>
              <Button
                component="a"
                color="green"
                variant="outline"
                size="md"
                loading={loading}
              >
                Take me back home
              </Button>
            </Link>
          )}
        </Group>
      </Stack>
    </Container>
  );
}

export default NotFoundPage;
